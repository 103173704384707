/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { navigate } from 'gatsby';
import { useEffect } from 'react';

export default function ProductRedirect(props) {
  const { exhibitorId, productId } = props.params;
  useEffect(() => {
    navigate(
      `/partners/${exhibitorId}/products/${productId}?utm_source=qrcode`
    );
  });
  return null;
}
